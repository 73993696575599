<template>
  <v-app>
    <dashboard-core-app-bar
      :init-title="title"
      :searchFunction="searchFunction"
      :searchParamString="searchParamString"
    />

    <dashboard-core-drawer />

    <dashboard-core-view>
      <slot></slot>
    </dashboard-core-view>

    <dashboard-core-settings />
    <validation-handler />
  </v-app>
</template>

<script>
const ValidationHandler = () => import('@/components/utils/validation-handler');

export default {
  name: 'DashboardIndex',
  props: ['title', 'searchFunction', 'searchParamString'],
  components: {
    DashboardCoreAppBar: () => import('@/components/core/app-bar'),
    DashboardCoreDrawer: () => import('@/components/core/drawer'),
    DashboardCoreSettings: () => import('@/components/core/settings'),
    DashboardCoreView: () => import('@/components/core/view'),
    ValidationHandler,
  },

  data: () => ({
    expandOnHover: false,
  }),
};
</script>
